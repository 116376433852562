<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <!-- <div v-if="deviceTypeId === $constRepository.deviceType.DEVICE_TYPE['空调']">
        <a-divider />
        <a-form-item label="模式列表:">
          <a-button type="primary" @click="handleAddMode" class="add-btn">添加空按键</a-button>
          <a-table
            v-if="form"
            bordered
            :data-source="form.modes"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <a-table-column title="id" width="150px">
              <template slot-scope="scope">
                <div v-if="!scope.editable">{{ scope.id }}</div>
                <a-input v-model="scope.id" clearable v-if="scope.editable"></a-input>
              </template>
            </a-table-column>
            <a-table-column title="温度" width="200px">
              <template slot-scope="scope">
                <div v-if="!scope.editable">{{ scope.temperature }}</div>
                <a-textarea
                  class="table-textarea"
                  :autoSize="true"
                  v-model="scope.temperature"
                  clearable
                  v-if="scope.editable"
                ></a-textarea>
              </template>
            </a-table-column>
            <a-table-column title="风速" width="200px">
              <template slot-scope="scope">
                <div v-if="!scope.editable">{{ scope.speed }}</div>
                <a-textarea
                  class="table-textarea"
                  :autoSize="true"
                  v-model="scope.speed"
                  clearable
                  v-if="scope.editable"
                ></a-textarea>
              </template>
            </a-table-column>
            <a-table-column title="操作" width="150px">
              <template slot-scope="scope">
                <a-button type="link" size="small" @click="edit(scope)" v-if="!scope.editable">编 辑</a-button>
                <a-button type="link" size="small" @click="save(scope)" v-if="scope.editable">保 存</a-button>
                <a-divider type="vertical" />
                <a-button class="red-btn" ghost @click="onDelete(form.modes, scope.key)">删 除</a-button>
              </template>
            </a-table-column>
          </a-table>
        </a-form-item>
      </div> -->
      <a-divider />
      <a-form-item label="按键列表:">
        <a-button type="primary" @click="handleAdd" class="editable-add-btn">添加新按钮</a-button>
        <div class="btn-group">
          <a-button type="primary" class="download-excel" @click="exportJSON">导出</a-button>
          <label for="fileinp">
            <a-button type="primary" class="btn"
              >导入
              <input
                type="file"
                name="filename"
                ref="upload"
                accept=".json"
                class="outputlist_upload"
                id="fileinp"
                multiple="multiple"
              />
            </a-button>
          </label>
          <a-button type="primary" class="btn" @click="importTemplate">引入模板 </a-button>
        </div>
        <a-table
          bordered
          :data-source="form.keys"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <a-table-column title="按钮" width="150px">
            <template slot-scope="scope">
              <a-select
                v-model="scope.id"
                allowClear
                placeholder="请选择按钮"
                showSearch
                v-if="deviceTemplate"
                optionFilterProp="children"
              >
                <a-select-option v-for="template in deviceTemplate" :key="template.keyId">{{
                  template.displayName
                }}</a-select-option>
              </a-select>
            </template>
          </a-table-column>
          <a-table-column title="时间码" width="400px">
            <template slot-scope="scope">
              <div>
                <div class="table-div" v-if="!scope.editable">{{ scope.pulse }}</div>
                <a-textarea v-else class="table-textarea" :autoSize="true" v-model="scope.pulse" clearable></a-textarea>
              </div>
            </template>
          </a-table-column>
          <a-table-column title="操作" width="150px">
            <template slot-scope="scope">
              <a-button type="link" size="small" @click="edit(scope)" v-if="!scope.editable">编辑</a-button>
              <a-button type="link" size="small" @click="save(scope)" v-else>保存</a-button>
              <a-divider type="vertical" />
              <!-- <a-button type="link" size="small" @click="showModal(index)">扩展</a-button>
              <a-divider type="vertical" /> -->
              <a-button class="red-btn" ghost @click="onDelete(form.keys, scope)">删除</a-button>
            </template>
          </a-table-column>
        </a-table>
      </a-form-item>
    </a-form-model>
    <a-modal :afterClose="resetModalKeys" centered v-model="visible" title="额外配置" @ok="handleOk" width="60%">
      <template>
        <a-button type="primary" @click="handleAddExts">添加空按键</a-button>
      </template>
      <a-table
        v-if="visible"
        bordered
        :data-source="form.keys[modalKey].exts"
        class="expand-tab"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <a-table-column title="标签" width="150px">
          <template slot-scope="scope">
            <div v-if="!scope.editable">{{ scope.tag }}</div>
            <a-input v-model="scope.tag" clearable v-if="scope.editable"></a-input>
          </template>
        </a-table-column>
        <a-table-column title="值" width="400px">
          <template slot-scope="scope">
            <div v-if="!scope.editable">{{ scope.value }}</div>
            <a-textarea
              class="table-textarea"
              :autoSize="true"
              v-model="scope.value"
              clearable
              v-if="scope.editable"
            ></a-textarea>
          </template>
        </a-table-column>
        <a-table-column title="操作" width="150px">
          <template slot-scope="scope">
            <a-button type="link" size="small" @click="edit(scope)" v-if="!scope.editable">编辑</a-button>
            <a-button type="link" size="small" @click="save(scope)" v-if="scope.editable">保存</a-button>
            <a-divider type="vertical" />
            <a-button class="red-btn" ghost @click="onDelete(form.keys[modalKey].exts, scope.key)">删除</a-button>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
  name: 'remote-controller',
  components: {},
  props: {
    form: {
      type: Object,
      default: null,
    },
    deviceTemplate: Array,
    deviceTypeId: String,
  },
  mounted() {
    this.$refs.upload.addEventListener('change', (e) => {
      this.importJSON(e);
    });
  },
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      serviceProviderId: null,
      brandList: null,
      brandId: null,
      modalKey: 0,
      regionId: null,
      districtList: null,
      cityList: null,
      serviceProviderList: null,
    };
  },
  watch: {
    editable: {},
  },
  methods: {
    importTemplate() {
      if (this.deviceTemplate && this.deviceTemplate.length !== 0) {
        this.form.keys = [];
        this.form.keys = this.deviceTemplate.map((template) => ({
          id: template.keyId,
          displayName: template.displayName,
          name: template.keyName,
          pulse: '',
        }));
      } else {
        this.$message.warn('该设备暂无模板');
      }
    },
    save(value) {
      this.$set(value, 'editable', false);
    },
    edit(value) {
      this.$set(value, 'editable', true);
    },
    resetModalKeys() {
      this.modalKey = 0;
    },
    handleOk() {
      this.visible = false;
    },
    onDelete(data, scope) {
      const dataSource = [...data].filter((item) => item !== scope);
      switch (data) {
        case this.form.keys:
          this.form.keys = dataSource;
          break;
        case this.form.modes:
          this.form.modes = dataSource;
          break;
        // case this.form.keys[this.modalKey].exts:
        //   this.form.keys[this.modalKey].exts = dataSource;
        //   break;
        default:
          break;
      }
    },
    exportJSON() {
      // 将json转换成字符串
      const exportData = this.form;
      exportData.keys.forEach((key, index) => {
        exportData.keys[index].keyId = key.id;
        exportData.keys[index].keyName = key.name;
        delete exportData.keys[index].id;
        delete exportData.keys[index].name;
      });
      const data = JSON.stringify(exportData);
      const blob = new Blob([data], { type: '' });
      FileSaver.saveAs(blob, '模板.json');
    },
    // 导入json
    importJSON() {
      let importData;
      const file = document.getElementById('fileinp').files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        try {
          importData = JSON.parse(reader.result).keys;
        } catch (e) {
          this.$message.warn('导入失败，请检查json文件格式');
        }
        if (importData) {
          importData.forEach((key, index) => {
            importData[index].id = key.keyId;
            importData[index].name = key.keyName;
            delete importData[index].keyId;
            delete importData[index].keyName;
            delete importData[index].displayName;
          });
          this.form.keys = importData;
        }
      };
    },
    // handleAddMode() {
    //   if (!this.form.modes) {
    //     this.form.modes = [];
    //     const newData = {
    //       key: 0,
    //       id: '',
    //       temperature: '',
    //       speed: '',
    //     };
    //     this.form.modes.push(newData);
    //   } else {
    //     const newData = {
    //       key: this.form.modes.length,
    //       id: '',
    //       temperature: '',
    //       speed: '',
    //     };
    //     this.form.modes.push(newData);
    //   }
    // },
    handleAddExts() {
      const newData = {
        key: this.form.keys[this.modalKey].exts.length,
        tag: '',
        value: '',
      };
      this.form.keys[this.modalKey].exts.push(newData);
    },
    handleAdd() {
      const newData = {
        // key: this.form.keys.length,
        id: '',
        pulse: '',
        // exts: [],
      };
      this.form.keys.push(newData);
    },
  },
};
</script>

<style lang="less" scoped>
.table-textarea {
  overflow: hidden;
}
.table-div {
  height: auto;
}
.btn {
  z-index: 100;
  margin-right: 2%;
  float: left;
}
.add-btn {
  float: right;
  z-index: 100;
  margin-bottom: 3%;
}
.edit-btn {
  color: green;
}
.delete-btn {
  color: red;
}
.creator-btn {
  margin-left: 5%;
}
.service-provicer-operations {
  display: flex;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .service-table {
    margin-top: 2%;
  }
}
.group-btn {
  margin-left: 16.7%;
}
.vue-xlsx-container {
  .xlsx-button {
    width: 10%;
  }
}
.download-excel {
  margin-right: 2%;
  z-index: 100;
  float: left;
}
.editable-add-btn {
  float: right;
  z-index: 100;
  margin: 5px 0px;
}
label {
  position: relative;
}
#fileinp {
  float: left;
  margin-left: 2%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  .btn {
    margin-left: 2%;
  }
}
.expand-tab {
  margin-top: 2%;
}
.red-btn {
  color: red;
  border: none;
}
</style>
