<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="form" :rules="rules" ref="form">
      <a-collapse defaultActiveKey="1">
        <a-collapse-panel key="1" header="码库编辑">
          <div v-if="form">
            <a-form-model-item label="码库id">
              <a-input v-model="form.remoteControllerId" disabled />
            </a-form-model-item>
            <a-form-model-item label="名称" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
            <a-form-model-item label="频率" prop="frequency">
              <a-input v-model="form.frequency" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea
                v-model="form.remark"
                placeholder="码库备注"
                :auto-size="{ minRows: 10, maxRows: 20 }"
                size="large"
              />
            </a-form-model-item>
            <remote-controller
              :deviceTemplate="pulseInfo"
              :form="form"
              :deviceTypeId="deviceTypeId"
            ></remote-controller>
            <div class="creator-btn">
              <a-button type="primary" @click="editRemoteController">保存</a-button>
              <a-button class="cancel-btn" @click="cancel">返回</a-button>
            </div>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="新增绑定关系">
          <a-form-model-item label="设备类型" v-if="deviceTypeOptions">
            <a-select v-model="deviceTypeId" allowClear placeholder="请选择设备类型" style="width: 320px" disabled>
              <a-select-option v-for="deviceType in deviceTypeOptions" :key="deviceType.deviceTypeId">{{
                deviceType.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <div v-if="brandList">
            <a-form-model-item label="品牌" v-if="brandList">
              <a-select
                v-model="relsForm.brandSelect"
                label-in-value
                mode="multiple"
                showSearch
                allowClear
                placeholder="请选择品牌"
                style="width: 320px"
              >
                <a-select-option v-for="brand in brandList" :key="`${brand.name}-${brand.brandId}`">{{
                  brand.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div v-if="districtList">
            <a-form-model-item label="服务商所在省份" v-if="districtList">
              <a-select allowClear placeholder="请选择服务商所在省份" style="width: 320px" @change="districtChange">
                <a-select-option v-for="district in districtList" :key="district.districtCode">{{
                  district.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="服务商所在城市" v-if="cityList">
              <a-select allowClear placeholder="请选择服务商所在城市" style="width: 320px" @change="cityChange">
                <a-select-option v-for="city in cityList" :key="city.districtCode">{{ city.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="服务商" v-if="serviceProviderList">
              <a-select
                mode="multiple"
                v-model="relsForm.providerSelect"
                allowClear
                showSearch
                placeholder="请选择服务商"
                style="width: 320px"
              >
                <a-select-option
                  v-for="provider in serviceProviderList"
                  :key="`${provider.serviceProviderId}-${provider.name}`"
                  >{{ provider.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </div>
          <!-- <a-form-model-item label="优先级">
            <a-input style="width: 320px" v-model="rank" />
          </a-form-model-item> -->
          <a-form-item class="group-btn">
            <a-button type="primary" @click="createRemoteRels">添加</a-button>
            <a-button @click="cancel">取消</a-button>
          </a-form-item>
        </a-collapse-panel>
        <a-collapse-panel
          key="3"
          header="服务商绑定"
          v-if="deviceTypeId === $constRepository.deviceType.DEVICE_TYPE['机顶盒']"
        >
          <a-form-item label="已绑定服务商列表:" v-if="form">
            <a-table bordered :data-source="form.spRemoteRelDtos" rowKey="spRemoteRelId">
              <a-table-column title="服务商" key="serviceProviderId">
                <template slot-scope="scope">
                  <div v-if="scope.serviceProvider">{{ scope.serviceProvider.name }}</div>
                </template>
              </a-table-column>
              <a-table-column title="城市" key="districtCode">
                <template slot-scope="scope">
                  <div>{{ scope.nationDistrict.name }}</div>
                </template>
              </a-table-column>
              <a-table-column key="action" title="操作">
                <template slot-scope="text, record">
                  <span>
                    <a-button class="red-btn" ghost>
                      <a-popconfirm
                        okText="是"
                        cancelText="否"
                        title="是否删除服务商绑定?"
                        @confirm="() => deleteSpRemoteRels(record)"
                      >
                        <a href="javascript:;">删除</a>
                      </a-popconfirm>
                    </a-button>
                  </span>
                </template>
              </a-table-column>
            </a-table>
          </a-form-item>
        </a-collapse-panel>
        <a-collapse-panel key="4" header="品牌绑定">
          <a-form-item label="已绑定品牌列表:" v-if="form">
            <a-table bordered :data-source="form.brandRemoteRelDtos" rowKey="brandRemoteRelId">
              <a-table-column title="品牌" key="brandId">
                <template slot-scope="scope">
                  <div>{{ scope.brand.name }}</div>
                </template>
              </a-table-column>
              <a-table-column title="设备" key="deviceTypeId">
                <template slot-scope="scope">
                  <div>{{ scope.deviceType.name }}</div>
                </template>
              </a-table-column>
              <a-table-column key="action" title="操作">
                <template slot-scope="text, record">
                  <span>
                    <a-button class="red-btn" ghost>
                      <a-popconfirm
                        okText="是"
                        cancelText="否"
                        title="是否删除品牌绑定?"
                        @confirm="() => deleteBrandRemoteRels(record)"
                      >
                        <a href="javascript:;">删除</a>
                      </a-popconfirm>
                    </a-button>
                  </span>
                </template>
              </a-table-column>
            </a-table>
          </a-form-item>
        </a-collapse-panel>
      </a-collapse>
    </a-form-model>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
// import lodash from 'lodash';
import RemoteController from '../../../components/remote-controller/RemoteController';

export default {
  name: 'RemoteControllerEdit',
  components: { RemoteController },
  mounted() {
    this.form = this.$route.params.remoteController;
    if (!this.form) {
      this.$router.back();
    } else {
      if (this.form.brandRemoteRelDtos) {
        this.infraredtemplates(this.form.brandRemoteRelDtos[0].deviceTypeId);
      } else {
        this.infraredtemplates(this.$constRepository.deviceType.DEVICE_TYPE['机顶盒']);
      }
      this.getremoteControllerDetail(this.form.remoteControllerId);
      if (this.form.modes) {
        this.form.modes.forEach((mode, index) => {
          this.$set(mode, 'key', index);
        });
      }
      this.getDeviceTypes();
    }
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: '码库名称 不能为空', trigger: 'blur' }],
        frequency: [{ required: true, message: '频率 不能为空', trigger: 'blur' }],
        remark: [{ required: true, message: '备注 不能为空', trigger: 'blur' }],
      },
      relsForm: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      deviceTypeId: null,
      deviceTypeOptions: null,
      districtList: null,
      cityList: null,
      serviceProviderList: null,
      brandList: null,
      brandId: null,
      form: {},
      pulseInfo: undefined,
    };
  },
  watch: {
    editable: {},
  },
  methods: {
    async getremoteControllerDetail(params) {
      await this.$apiManager.remoteControllers.getRemoteControllerDetail(params).then((remoteControllers) => {
        this.form.keys = remoteControllers.data[0].keys;
        this.form.brandRemoteRelDtos = remoteControllers.data[0].brandRemoteRelDtos;
        this.form.spRemoteRelDtos = remoteControllers.data[0].spRemoteRelDtos;
        if (this.form.brandRemoteRelDtos) {
          this.deviceTypeId = this.form.brandRemoteRelDtos[0].deviceTypeId;
        } else {
          this.deviceTypeId = this.$constRepository.deviceType.DEVICE_TYPE['机顶盒'];
        }
        this.handleChange(this.deviceTypeId);
        this.form.keys.forEach((keys, keyIndex) => {
          this.$set(keys, 'key', keyIndex);
          // keys.exts.forEach((ext, index) => {
          //   this.$set(ext, 'key', index);
          // });
        });
      });
    },
    infraredtemplates(value) {
      this.pulseInfo = [];
      this.$apiManager.deviceType.infraredtemplates(value).then((template) => {
        if (template.data.keys) {
          this.pulseInfo = template.data.keys;
        } else {
          this.pulseInfo = [];
        }
      });
    },
    handleChange(value) {
      this.districtList = null;
      this.brandList = null;
      this.cityList = null;
      this.serviceProviderList = null;
      this.deviceTypeId = value;
      this.$apiManager.brand.getBrands({ unpaged: true }).then((brand) => {
        this.brandList = brand.data.elements;
      });
      if (this.deviceTypeId === this.$constRepository.deviceType.DEVICE_TYPE['机顶盒']) {
        this.$apiManager.district.getDistrict().then((district) => {
          this.districtList = district.data;
        });
      }
    },
    deleteSpRemoteRels(spRemoteRels) {
      if (this.form.brandRemoteRelDtos || this.form.spRemoteRelDtos.length !== 1) {
        this.$apiManager.remoteControllers
          .deleteSpRemoteRels(spRemoteRels.spRemoteRelId)
          .then(() => {
            this.$message.success('删除成功');
            this.getremoteControllerDetail(this.form.remoteControllerId);
          })
          .catch(() => {});
      } else {
        this.$message.warn('不可删除最后一个绑定');
      }
    },
    deleteBrandRemoteRels(brandRemoteRels) {
      if (this.form.spRemoteRelDtos || this.form.brandRemoteRelDtos.length !== 1) {
        this.$apiManager.remoteControllers
          .deleteBrandRemoteRels(brandRemoteRels.brandRemoteRelId)
          .then(() => {
            this.$message.success('删除成功');
            this.getremoteControllerDetail(this.form.remoteControllerId);
          })
          .catch(() => {});
      } else {
        this.$message.warn('不可删除最后一个绑定');
      }
    },
    districtChange(value) {
      this.cityList = null;
      this.serviceProviderList = null;
      this.$apiManager.district.getCity(value).then((district) => {
        this.cityList = district.data;
      });
    },
    cityChange(value) {
      this.serviceProviderList = null;
      this.regionId = value;
      this.$apiManager.serviceProvider.getServiceProviders({ unpaged: true }).then((provider) => {
        this.serviceProviderList = provider.data.elements;
      });
    },
    cancel() {
      this.$router.back();
    },
    editRemoteController() {
      this.$refs.form.validate((validate) => {
        if (validate) {
          const params = clonedeep(this.form);
          delete params.brandRemoteRelDtos;
          delete params.spRemoteRelDtos;
          this.$apiManager.remoteControllers
            .updateRemoteController(params)
            .then(() => {
              this.$message.success('修改成功');
            })
            .catch(() => {});
        }
      });
    },
    providerRels() {
      const providerList = [];
      if (this.relsForm.providerSelect) {
        this.relsForm.providerSelect.forEach((provider) => {
          const params = {
            spRemoteRelId: this.$uuid.v1(),
            remoteId: this.form.remoteControllerId,
            serviceProviderId: provider.split('-')[0],
            regionId: this.regionId,
            rank: 0,
          };
          providerList.push(params);
        });
        this.$apiManager.remoteControllers
          .createSpRemoteRels(providerList)
          .then(() => {
            this.$message.success('新增成功');
            this.getremoteControllerDetail(this.form.remoteControllerId);
            this.relsForm.providerSelect = undefined;
          })
          .catch(() => {});
      }
    },
    brandRels() {
      const brandRemoteRels = [];
      if (this.relsForm.brandSelect) {
        this.relsForm.brandSelect.forEach((brand) => {
          const params = {
            deviceTypeId: this.deviceTypeId,
            remoteId: this.form.remoteControllerId,
            brandId: brand.key.split('-')[1],
            rank: 0,
            brandRemoteRelId: this.$uuid.v4(),
          };
          brandRemoteRels.push(params);
        });
        this.$apiManager.remoteControllers
          .createBrandRemoteRels(brandRemoteRels)
          .then(() => {
            this.$message.success('新增成功');
            this.getremoteControllerDetail(this.form.remoteControllerId);
            this.relsForm.brandSelect = undefined;
          })
          .catch(() => {});
      }
    },
    async createRemoteRels() {
      if (this.deviceTypeId && this.deviceTypeId === this.$constRepository.deviceType.DEVICE_TYPE['机顶盒']) {
        await this.providerRels();
        this.brandRels();
      } else {
        this.brandRels();
      }
    },
    async getDeviceTypes() {
      const params = {
        unpaged: true,
      };
      const deviceTypeResponse = await this.$apiManager.deviceType.getDeviceTypes(params);
      this.deviceTypeOptions = deviceTypeResponse.data.elements;
    },
  },
};
</script>

<style lang="less" scoped>
.add-btn {
  float: right;
  z-index: 100;
  margin-bottom: 3%;
}
.edit-btn {
  color: green;
}
.delete-btn {
  color: red;
}
.creator-btn {
  text-align: center;
  .cancel-btn {
    margin-left: 5%;
  }
}
.service-provicer-operations {
  display: flex;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .service-table {
    margin-top: 2%;
  }
}
.group-btn {
  margin-left: 16.7%;
}
.vue-xlsx-container {
  .xlsx-button {
    width: 10%;
  }
}
.editable-add-btn {
  float: right;
  margin: 5px 0px;
}
label {
  position: relative;
}
#fileinp {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
#btn {
  margin-right: 5px;
  border-color: #78c3f3;
  text-decoration: none;
  position: relative;
  display: inline-block;
  background: #d0eeff;
  border: 1px solid #99d3f5;
  border-radius: 4px;
  padding: 4px 12px;
  overflow: hidden;
  color: #1e88c7;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
}
.expand-tab {
  margin-top: 2%;
}
.red-btn {
  color: red;
  border: none;
}
</style>
